import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message  } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");

class Area extends Component {
  constructor(props) {
    super(props);
    this.state = {   
      codigo_area:'',
      descricao_area:'',
      abreviatura_area:'',
      codigo_unidade_fabril:'',
      descricao_unidade_fabril:[],
      loading:'',
      disabled:'',
      display:displayNone
    };
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true,
      codigo_unidade_fabril: this.props.UnidadeFabril[0].codigo_unidade_fabril
    });
    
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/unidadefabril',
      headers: {'x-access-token':getToken()}
    };
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        var unidades_fabris = data.map(function(data){
          return { key: data.codigo_unidade_fabril, text: data.abreviatura_unidade_fabril +' - '+ data.descricao_unidade_fabril , value: data.codigo_unidade_fabril }
        })
        this.setState({   
          unidades_fabris : unidades_fabris
        })
      }            
    }.bind(this)); 
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    if (input.getElementsByTagName('span').length === 0) {
      this.setState(
        {[name]: value.toUpperCase()},
        () => input.setSelectionRange(start, end)
      );
    } else {
      this.setState(
        {[name]: value.toUpperCase()}
      );
    } 
  }
  
  salvar(){
    if(this.state.codigo_area.length < 2 ||
       this.state.descricao_area.length < 2 ||
       this.state.abreviatura_area.length < 2){
      this.setState({display:display})
    }else{
      this.setState({loading:'true',disabled:'true'});
      this.setState({display:displayNone})
      var options = {
        method: 'POST',
        url: 'https://inteligenciaindustrial.com/engind/area',
        headers: {
          'content-type': 'application/json',
          'x-access-token':localStorage.getItem("token")
        },
        body: this.state,
        json: true
      };
      
      request(options, function (error, response, body) {
        if (error){
          console.log(error)
        }else{
          this.setState({   
            codigo_area:'',
            descricao_area:'',
            abreviatura_area:'',
            codigo_unidade_fabril:'',
            loading:'',
            disabled:''
          })
        }            
      }.bind(this));  
    }
  }
  
  render(){
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='sitemap' content='Área' />
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={3} control={Input} label='Código' placeholder='Código'  name='codigo_area' onChange={this.altera.bind(this)} value={this.state.codigo_area}/>
              <Form.Field width={10} control={Input} label='Descrição' placeholder='Descrição'  name='descricao_area' onChange={this.altera.bind(this)} value={this.state.descricao_area}/>
              <Form.Field width={3} control={Input} label='Abreviatura'  placeholder='Abreviatura'  name='abreviatura_area' onChange={this.altera.bind(this)} value={this.state.abreviatura_area}/>
            </Form.Group>                               
          </Form>
          <Message negative style={this.state.display}>
            <Icon name='info circle' />
              Não foi possível salvar os dados. Verifique o preenchimento dos campos.
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelar.bind(this)} color='grey'>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
            <Icon name='save outline' /> Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Area);