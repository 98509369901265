export const TOKEN_KEY = "token";
export const KEY = "key";
export const tokenValido = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getKeyUsuario = () => localStorage.getItem(KEY);

export const salvaToken = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const removerToken = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(KEY);
};
