import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message  } from 'semantic-ui-react';

import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { getToken } from '../../servicos/controletoken.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const request = require("request");

class ResponsavelArea extends Component{
  constructor(props) {
    super(props);
    this.state = {     
      data:[],
      colunas:[
        {headerName: "CÓDIGO ÁREA", field: "codigo_area", width:175},
        {headerName: "ÁREA", field: "descricao_area",width:205},
        {headerName: "CÓDIGO USUÁRIO", field: "codigo_usuario",width:175},
        {headerName: "USUÁRIO", field: "usuario",width:275}
      ]
    };
  }
  
  componentDidMount(){    
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/responsavelarea/lista',
      headers: {'x-access-token':getToken()}
    };
    
   request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);        
        this.setState({   
          data : data
        })
            
      }            
    }.bind(this));
    
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal(JSON.stringify(''));
    this.setState({
      exibeModal: false
    });
  }
  
  render() {
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='user outline' content='Responsável por Área' />
        <Modal.Content>
                   <div 
                      className="ag-theme-material"
                      style={{ 
                      height: '300px', 
                      width: '850px'
                    }} 
                    >
                         <AgGridReact 
                            style={{fontSize:'10px !important', width:'830px', height:'350px'}}
                            rowSelection='multiple'                        
                            enableColResize={true}                  
                            enableSorting={true}    
                            enableFilter={true}
                            columnDefs={this.state.colunas}
                            rowData={this.state.data}>
                        </AgGridReact>
                  </div>

        </Modal.Content>   
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResponsavelArea);