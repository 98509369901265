import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Dimmer, Loader } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");
const axios = require("axios");

class Projeto extends Component{
  constructor(props) {
    super(props);
    this.state = {  
      codigo_projeto:'',
      descricao_projeto:'',   
      codigo_area:'',
      abreviatura_area:'',
      pre_path:'',
      path:'',
      detalhes_arquivo:'',
      listaCodigos:[],
      areas:[],
      listaArea:[],
      loading:'',
      disabled:'',
      nome_original: '',
      display:displayNone,
      displayUploadSucesso:displayNone,
      displayErroUpload:displayNone,
      displayNoImage:displayNone,
      loader_projetos: false,
      loader_areas: false,
      loader_arquivos: false,
      CaminhosPrincipais: [],
      Caminhos: [],
      listaCodigoProjetos: []
    };
    
    this.salvarArquivos = this.salvarArquivos.bind(this);
    this.selecionaArquivos = this.selecionaArquivos.bind(this);
  }
  
  componentDidMount(){ 
    this.setState({
      exibeModal: true,
      loader_projetos: true,
      loader_areas: true,
      loader_arquivos: true
    });
    
    var options2 = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/projeto',
      headers: {        
        'x-access-token':getToken()
      }
    }; 
    
    request(options2, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        let data = JSON.parse(body);
        let projetosFiltrados = data.filter(projeto => {
          return projeto.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        let listaCodigos = projetosFiltrados.map(function(item){return {key:item.codigo_projeto,text:item.codigo_projeto,value:item.codigo_projeto}});        
        let listaCodigoProjetos = [];
        for (var index = 0; index < projetosFiltrados.length; index++) {
          listaCodigoProjetos.push(projetosFiltrados[index].codigo_projeto);
        }
        this.setState({
          listaCodigos:listaCodigos,
          projetos:projetosFiltrados,
          listaCodigoProjetos: listaCodigoProjetos,
          loader_projetos: false
        })
      }            
    }.bind(this));
    
    
    var options3 = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/area',
      headers: {        
        'x-access-token':getToken()
      }
    }; 
    
    request(options3, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        let data = JSON.parse(body);
        let areasFiltrada = data.filter(area => {
          return area.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        let listaArea = areasFiltrada.map(function(item){return {key:item.codigo_area,text:item.abreviatura_area,value:item.codigo_area}});        
        this.setState({
          listaArea:listaArea,
          areas: areasFiltrada,
          loader_areas: false
        })
      }            
    }.bind(this));
    
    var optionsArquivos = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/engind/arquivos',
      headers: {
        'content-type': 'application/json'        
      },
      body:{"usuario":"engind","senha":"engind@123"},
      json: true
    };
    
    request(optionsArquivos, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = body;
        var dataFiltrado = [];
        for (var index = 0; index < data.length; index++) {
          if (this.state.listaCodigoProjetos.indexOf(data[index].codigo_projeto) > -1) {
            dataFiltrado.push(data[index]);
          }
        }
        let CaminhosPrincipais = [];
        let Caminhos = [];
        dataFiltrado.forEach(function(x) {
          if (x.path !== 'undefined') {
            CaminhosPrincipais.push(x.pre_path);
            Caminhos.push(x.path);
          }
        });
        CaminhosPrincipais = CaminhosPrincipais.filter(function(este, i) {
          return CaminhosPrincipais.indexOf(este) === i;
        });
        Caminhos = Caminhos.filter(function(este, i) {
          return Caminhos.indexOf(este) === i;
        });
        
        let opcCaminhosPrincipais = [];
        let opcCaminhos = [];
        CaminhosPrincipais.forEach(function(y) {
          opcCaminhosPrincipais.push({key: Math.floor(Math.random() * (100000 - 0 + 1) + 0), text: y, value:y})
        });
        Caminhos.forEach(function(e) {
          opcCaminhos.push({key: Math.floor(Math.random() * (200000 - 100001 + 1) + 100001), text: e, value:e})
        })
        this.setState({
          loader_arquivos: false,
          CaminhosPrincipais: opcCaminhosPrincipais,
          Caminhos: opcCaminhos
        })
      }            
    }.bind(this));
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {[name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  
  alteraSelect(e, {value,name}) {
    this.setState({
      [name]: value.toUpperCase()
    })
  }
  
  salvar(){
    if(this.state.codigo_projeto.length < 2 ||
       this.state.descricao_projeto.length < 2 ||
        this.state.codigo_area.length < 2 ||
        this.state.path < 2 ||
        this.state.pre_path < 2 ){
      this.setState({display:display})
    }else{
      this.setState({loading:'true',disabled:'true'});
      this.setState({display:displayNone})
      var options = {
        method: 'POST',
        url: 'https://inteligenciaindustrial.com/engind/projeto',
        headers: {
          'content-type': 'application/json',
          'x-access-token':getToken()
        },
        body: this.state,
        json: true
      };
      
      request(options, function (error, response, body) {
        if (error){
          console.log(error)
        }else{
          this.setState({
            codigo_projeto:'',
            descricao_projeto:'',
            loading:'',
            disabled:''
          })
        }            
      }.bind(this));  
    }
  }
  
  
  salvarArquivos(e){
    //console.log(this.state.file.name);
    if (this.state.file !== undefined) {
      this.setState({
        displayNoImage: displayNone
      });
      if(this.state.codigo_projeto.length < 2 ||
         this.state.descricao_projeto.length < 2 ||
         this.state.codigo_area.length < 2 ||
         this.state.path < 2 ||
         this.state.pre_path < 2 ){
        this.setState({display:display})
      }else{ 
        this.setState({loading:'true',disabled:'true', display: displayNone, displayNoImage: displayNone});
        e.preventDefault();
        const formData = new FormData();
        formData.append('myImage',this.state.file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };
        let nomeArquivoCompleto = this.state.file.name.split('-');
        let nomeArquivo = nomeArquivoCompleto[0] + "-" + nomeArquivoCompleto[1] + "-" + nomeArquivoCompleto[2] + "-" + 
                          nomeArquivoCompleto[3] + "-" + nomeArquivoCompleto[4] + "-" + nomeArquivoCompleto[5] + "-" +
                          nomeArquivoCompleto[6];
        
        let extensaoArquivo = this.state.file.name.split('.')[this.state.file.name.split('.').length-1];
        
        
        let PathArquivo = this.state.path.split('-');
        let nomeArquivoOriginal = this.state.nome_original;
        
        
        if (PathArquivo[PathArquivo.length-1] === 'VERSÕES ANTIGAS') {
          //REALIZA O UPLOAD DO ARQUIVO
          axios.post("https://inteligenciaindustrial.com/engind/arquivos/upload/engind&engind@123"+"&"+this.state.codigo_projeto+"&"+this.state.path + "&"+this.state.codigo_area+"&"+""+"&"+this.state.pre_path+"&"+""+"&"+"",formData,config)
            .then((response) => {
              console.log(response.data.id);
              var salvaNomeObservacaoArquivo = {
                method: 'POST',
                url: 'https://inteligenciaindustrial.com/engind/arquivos/atualizanomeobservacaoarquivo',
                headers: {
                  'content-type': 'application/json',
                  'x-access-token':getToken()
                },
                body: {'usuario': "engind", 'senha': "engind@123", 'id': response.data.id, 'detalhes_arquivo': this.state.detalhes_arquivo, 'nome_original': nomeArquivoOriginal},
                json: true
              };
              request(salvaNomeObservacaoArquivo, function (error, response, body){
                console.log(body);
                this.setState({loading:'',disabled:'',displayUploadSucesso:display,detalhes_arquivo:'', nome_original: ''});
              }.bind(this));
            }).catch((error) => {      
              this.setState({loading:'',disabled:'', displayErroUpload:display,detalhes_arquivo:'', nome_original: ''});
            });
        } else {
          if (PathArquivo[PathArquivo.length-1] === 'VERSÕES ATUAIS') {
            PathArquivo.pop();
          }
          let Path = '', tam = PathArquivo.length;
          tam--;
          for (var i = 0; i < PathArquivo.length; i++) {
            if (i < tam) {
              Path += PathArquivo[i] + '-';
            } else {
              Path += PathArquivo[i];
            }
          }
          var verificaArquivo = {
            method: 'POST',
            url: 'https://inteligenciaindustrial.com/engind/arquivos/verificaarquivo',
            headers: {
              'content-type': 'application/json',
              'x-access-token':getToken()
            },
            body: {'usuario': "engind", 'senha': "engind@123", 'nomeArquivo': nomeArquivo, 'extensaoArquivo': extensaoArquivo, 'pre_path': this.state.pre_path, 
                   'path': Path + "-VERSÕES ATUAIS", 'codigo_area': this.state.codigo_area, 'codigo_projeto': this.state.codigo_projeto},
            json: true
          };
          
          request(verificaArquivo, function (error, response, body) {
            if (error){
              console.log(error)
            }else{
              if (body) {
                axios.post("https://inteligenciaindustrial.com/engind/arquivos/upload/engind&engind@123"+"&"+this.state.codigo_projeto+"&"+Path + "-VERSÕES ATUAIS" + "&"+this.state.codigo_area+"&"+""+"&"+this.state.pre_path+"&"+""+"&"+"",formData,config)
                  .then((response) => {     
                  console.log(response.data.id);
                  var salvaNomeObservacaoArquivo = {
                    method: 'POST',
                    url: 'https://inteligenciaindustrial.com/engind/arquivos/atualizanomeobservacaoarquivo',
                    headers: {
                      'content-type': 'application/json',
                      'x-access-token':getToken()
                    },
                    body: {'usuario': "engind", 'senha': "engind@123", 'id': response.data.id, 'detalhes_arquivo': this.state.detalhes_arquivo, 'nome_original': nomeArquivoOriginal},
                    json: true
                  };
                  request(salvaNomeObservacaoArquivo, function (error, response, body){
                    console.log(body);
                    this.setState({loading:'',disabled:'',displayUploadSucesso:display,detalhes_arquivo:'', nome_original:''});
                  }.bind(this));
                }).catch((error) => {      
                  this.setState({loading:'',disabled:'', displayErroUpload:display,detalhes_arquivo:'', nome_original: ''});
                });
              } else {
                console.log("Erro ao salvar o arquivo");  
              }
            }            
          }.bind(this));
          
        }
      }
    } else {
      this.setState({
        displayNoImage: display
      });
    }
  }
  
  selecionaArquivos(e) {
    let arrayArquivos = [];
    for (let index = 0; index < e.target.files.length; index++) {
      arrayArquivos.push(e.target.files[index]);
    }
    this.setState({ file:e.target.files[0], ArrayArquivos: arrayArquivos });
  }
  
  filtraLista(e, {value,name}) {
    this.setState({[name]:value.toUpperCase(),displayUploadSucesso:displayNone,displayErroUpload:displayNone,display:displayNone});     
    let unidades_filtrado = this.state.projetos.filter(function(data){ return data.codigo_projeto === value.toUpperCase()  })      
    this.setState({ 
      key:unidades_filtrado[0].key,
      codigo_projeto:unidades_filtrado[0].codigo_projeto,
      descricao_projeto:unidades_filtrado[0].descricao_projeto          
    })
  }
  
  filtraListaArea(e, {value,name}) {
    this.setState({[name]:value.toUpperCase(),displayUploadSucesso:displayNone,displayErroUpload:displayNone,display:displayNone});    
    let unidades_filtrado = this.state.areas.filter(function(data){ return data.codigo_area === value.toUpperCase()  })      
    this.setState({ 
      codigo_area:unidades_filtrado[0].codigo_area,          
      abreviatura_area:unidades_filtrado[0].abreviatura_area   
    })
  }
  
  render(){
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='upload' content='Upload' />
        { this.state.loader_projetos && this.state.loader_areas && this.state.loader_arquivos && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando projetos e áreas' />
          </Dimmer>
        }
        <Modal.Content>
           <Form>
            <Form.Group>
              <Form.Field width={4} control={Select} label='Código' placeholder='Código' name='codigo_projeto' onChange={this.filtraLista.bind(this)} value={this.state.codigo_projeto} options={this.state.listaCodigos} search/>
              <Form.Field width={12} control={Input} label='Nome do Projeto' placeholder='Nome do Projeto' name='descricao_projeto' onChange={this.altera.bind(this)} value={this.state.descricao_projeto}/>          
            </Form.Group>   
            <Form.Group>
              <Form.Field width={4} control={Select} label='Área' placeholder='Área' name='codigo_area' onChange={this.filtraListaArea.bind(this)} value={this.state.codigo_area} options={this.state.listaArea} search/>
            </Form.Group>   
            <Form.Group>
                <Form.Field width={8} control={Input} list='pre_path' label='Caminho Principal' placeholder='Caminho Principal' name='pre_path' onChange={this.alteraSelect.bind(this)} value={this.state.pre_path} />
                <datalist id='pre_path'>
                  {
                    this.state.CaminhosPrincipais.map(function(x){
                      return <option key = {x.key} value = {x.value} />
                    })
                  
                  }
                </datalist>          
                <Form.Field width={8} control={Input} list='path' label='Caminho' placeholder='Caminho' name='path' onChange={this.altera.bind(this)} value={this.state.path}></Form.Field>
                <datalist id='path'>
                  {
                    this.state.Caminhos.map(function(x){
                      return <option key = {x.key} value = {x.value} />
                    })
                  
                  }
                </datalist>
            </Form.Group>
            
            <Form.Group>
              <Form.Field width={16} control={Input} label='Nome Arquivo' placeholder='Nome para o arquivo' name='nome_original' onChange={this.altera.bind(this)} value={this.state.nome_original}/>          
            </Form.Group>

            <Form.Group>
              <Form.Field width={16} control={TextArea} label='Observação' placeholder='Observação' name='detalhes_arquivo' onChange={this.altera.bind(this)} value={this.state.detalhes_arquivo}/>          
            </Form.Group> 
            <form onSubmit = { this.salvarArquivos } style = {{textAlign: 'right'}}>        
            <input type = "file" name = "file" onChange = { this.selecionaArquivos } />
            <Button onClick={this.cancelar.bind(this)} color='grey' style = {{marginRight: '20px'}}>
              <Icon name='remove' /> Cancelar
            </Button>
            <Button  loading={this.state.loading} disabled={this.state.disabled} style={{backgroundColor:'#eb5c0a',color:'white', marginTop:'10px'}} type = "submit"> <Icon name='upload' />SALVAR</Button>
          </form>

          </Form>
          <Message negative style={this.state.displayNoImage}>
            <Icon name='info circle' />
            Selecione um arquivo para realizar o upload
          </Message>
          <Message negative style={this.state.displayErroUpload}>
            <Icon name='info circle' />
            Ocorreu algum erro ao realizar o Upload. Informe ao adimistrador do sistema.
          </Message>
          <Message warning style={this.state.display}>
            <Icon name='info circle' />
            Verique se todos os campos estão preenchidos corretamente!
          </Message>
          <Message success style={this.state.displayUploadSucesso}>
            <Icon name='info circle' />
            Upload realizado com sucesso!
          </Message>
        </Modal.Content>    
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Projeto);