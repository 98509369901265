import { UPDATE_TOKEN, UPDATE_UNIDADE_FABRIL, UPDATE_EXIBE_MODAL } from './actionTypes';

export const validaToken = value => ({
  type: UPDATE_TOKEN,
  data_token: value
});

export const alteraUnidadeFabril = value => ({
  type: UPDATE_UNIDADE_FABRIL,
  data_unidadeFabril: value
});

export const exibeModal = value => ({
  type: UPDATE_EXIBE_MODAL,
  data_exibeModal: value
});