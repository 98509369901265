import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css";
import { Header, Modal, Form, Select, Table, Segment, Image, Dimmer, Loader, Icon, Button } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';
import dwg from '../../imagens/iconesarquivos/dwg.png';
import pdf from '../../imagens/iconesarquivos/pdf.png';
import file from '../../imagens/iconesarquivos/file.png';
import jpeg from '../../imagens/iconesarquivos/jpge.png';

const request = require("request");

class Arquivos extends Component{
  constructor(props) {
    super(props);
    this.state = {  
      colunas: [{headerName: "PROJETO", field: "codigo_projeto", width:200},
                {headerName: "ÁREA", field: "area", width:200},
                {headerName: "ARQUIVO", field: "nome_arquivo", width:200}],
      data:[],
      dataCongelado:[],
      listaProjetos:[],
      projetos:[],

      column: null,    
      direction: null,
      loader: false,
      listaCodigoProjetos: [],
      modalExclusao: false, 
      keyArquivoDeletar: '',
      opcaoFiltro: '',
      codigo_projeto: ''
     
    };
    this.excluiArquivo = this.excluiArquivo.bind(this);
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true,
      loader: true
    });
    this.listaProjetos();
    this.listaDeArquivos();  
  }
  
  cancelar() {
    const { exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  listaProjetos(){
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/projeto',
      headers: {        
        'x-access-token':getToken()
      }
    }; 
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        let data = JSON.parse(body);
        let projetosFiltrados = data.filter(projeto => {
          return projeto.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        let listaCodigoProjetos = [];
        for (var index = 0; index < projetosFiltrados.length; index++) {
          listaCodigoProjetos.push(projetosFiltrados[index].codigo_projeto);
        }
        let listaProjetos = projetosFiltrados.map(function(item){return {key:item.codigo_projeto,text:item.descricao_projeto + ' ( '+ item.codigo_projeto+ ')',value:item.codigo_projeto}});        
            listaProjetos.unshift({key:'TUDO',text:'TUDO' ,value:'TUDO'})          
        this.setState({
          listaProjetos:listaProjetos,
          projetos:projetosFiltrados,
          listaCodigoProjetos: listaCodigoProjetos
        })
      }            
    }.bind(this));
    
  }
  
  listaDeArquivos(){
    var options = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/engind/arquivos',
      headers: {
        'content-type': 'application/json'        
      },
      body:{"usuario":"engind","senha":"engind@123"},
      json: true
    };
    
   request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = body;
        var dataFiltrado = [];
        for (var index = 0; index < data.length; index++) {
          if (this.state.listaCodigoProjetos.indexOf(data[index].codigo_projeto) > -1) {
            if (data[index].area === "undefined" || data[index].area === "") {
              data[index].area = "";
            }
            if (data[index].path === "undefined" || data[index].path === "") {
              data[index].path = "";
            }
            dataFiltrado.push(data[index]);
          }
        }
        this.setState({   
          data : dataFiltrado,
          dataCongelado : dataFiltrado,
          loader: false
        })
            
      }            
    }.bind(this));    
  }
  
  filtraProjeto(e, {value,name}){
    this.setState({
      loader: true
    });
    let data = this.state.dataCongelado.filter(function(item){
        if(value !== 'TUDO'){
          return item.codigo_projeto === value;
        }else{
          return item;
        }
    })    
    this.setState({ data:data, opcaoFiltro: value, loader: false })
  }
  
  download(e, row){
    //console.log(row._row.data.key)
    window.open('https://inteligenciaindustrial.com/engind/arquivos/download/'+row._cell.row.data.key);    
  }
  
  excluiArquivo(){
    this.setState({
      loader: true
    });
    const { keyArquivoDeletar } = this.state;
    var options = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/engind/arquivos/deletaarquivo',
      headers: {
        'content-type': 'application/json'        
      },
      body:{"usuario":"engind","senha":"engind@123", "keyArquivo":keyArquivoDeletar},
      json: true
    };
    request(options, function (error, response, body){
      this.setState({ modalExclusao: false, codigo_projeto: '' });
      this.listaDeArquivos();
    }.bind(this))
  }
  
  fechaModalExclusao = () => this.setState({ modalExclusao: false, keyProjetoDeletar: '' })
  
  exibeModalExclusao(e, row){
    console.log(row._cell.row.data.key)
    this.setState({ modalExclusao: true, keyArquivoDeletar: row._cell.row.data.key })
  }


  
  render(){
    const { data } = this.state;
    var deleteIcone = function(cell, formatterParams, onRendered){
      return "<i aria-hidden='true' class='close link icon large'></i>"
    };
    var imagenIcone = function(cell, formattersParams, onRendered){
      let iconSRC = cell._cell.row.data.extensao_arquivo === 'dwg' ? 'object group outline' : 
                    cell._cell.row.data.extensao_arquivo === 'pdf' ? 'file pdf outline' : 
                    cell._cell.row.data.extensao_arquivo === 'jpg' ? 'file image outline' :
                    cell._cell.row.data.extensao_arquivo === 'jpeg' ? 'file image outline' : 'file outline';
     // return `<img src='${iconSRC}' class='ui small image'/>`
      return `<i aria-hidden='true' class='${iconSRC} icon large'></i>`
    }
    const columns = [
      {
        title: "PROJETO", 
        field: "codigo_projeto",
        align: "center",
        width: 150, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado
      },
      {
        title: "ÁREA", 
        field: "area",
        align: "center",
        width : 65,
        headerFilter:"input",
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado
      },
      {
        title: "PASTA", 
        field: "path",
        align: "center",
        minWidth : 120, 
        headerFilter:"input",
        headerFilterPlaceholder:" ",
        responsive:3,
        headerFilterFunc:filtroCustomizado
      },
      { 
        title: "ARQUIVO", 
        field: "nome_arquivo",
        align: "left", 
        headerFilter: "input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado
      },
      {formatter:imagenIcone, width:40, align:"center", cellClick: this.download.bind(this)},
      {formatter:deleteIcone, width:40, align:"center", cellClick: this.exibeModalExclusao.bind(this)}
    ];
    let Options = {responsiveLayout:true};

    return(
      <div>
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='file alternate' content='Arquivos' />
        { this.state.loader && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando arquivos' />
          </Dimmer>
        }
        <ReactTabulator
          data={ data }
          style={{margin:'0px', fontSize:'9px', height: '480px'}}
          className={'very compact celled'}
          //responsiveLayout={true}
          options= {Options}
          columns={columns}
          tooltips={true}
          layout={"fitColumns"}
        />
      </Modal>

      <Modal open={this.state.modalExclusao} closeOnEscape={true} closeOnDimmerClick={false} onClose={this.fechaModalExclusao}>
          <Modal.Header>DELETAR ARQUIVO</Modal.Header>
          { this.state.loader && 
            <Dimmer active inverted>
              <Loader inverted content='Excluindo arquivo' />
            </Dimmer>
          }
          <Modal.Content>
            <p>VOCÊ TEM CERTEZA QUE DESEJA DELETAR O ARQUIVO?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.fechaModalExclusao} color = 'grey'>
              NÃO
            </Button>
            <Button
              onClick={this.excluiArquivo}
              loading={this.state.loader}
              color = 'orange'
              labelPosition='right'
              icon='checkmark'
              content='SIM'
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  } 
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Arquivos);

function filtroCustomizado(headerValue, rowValue, rowData, filterParams){
    

    let headerValueArr = headerValue.split(" ");
    
    let p = '';
    
    for(var x = 0; x < headerValueArr.length; x++){
        
        if(x<headerValueArr.length-1){
             p = p + 'rowValue.toUpperCase().match("'+headerValueArr[x]+'".toUpperCase()) && ';    
        }else{
            p = p + 'rowValue.toUpperCase().match("'+headerValueArr[x]+'".toUpperCase()) ';
        }
        
    }

    return eval(p) ; 
    
}
