import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Dimmer, Loader } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");

class Projeto extends Component{
  constructor(props) {
    super(props);
    this.state = {  
      codigo_projeto:'',
      descricao_projeto:'',      
      objetivo:'',
      descricao_geral:'',
      codigo_status_projeto:'',
      escopo:'',
      areas:[],
      status:[],
      loading:'',
      disabled:'',
      display:displayNone,
      displayData:displayNone,
      data: '',
      data_projeto: '',
      codigo_unidade_fabril: '',
      codigo_unidade_fabril_projeto: [],
      loader: false
    };
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true,
      codigo_unidade_fabril: this.props.UnidadeFabril[0].codigo_unidade_fabril,
      loader: true,
      loaderProjeto: true
    });
    
    var optionsStatusprojeto = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/statusprojeto',
      headers: {'x-access-token':getToken()}
    };
    
    request(optionsStatusprojeto, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        var status = data.map(function(data){
         return { key: data.codigo_status_projeto, text: data.descricao_status_projeto, value: data.codigo_status_projeto }
        })
        this.setState({   
          status : status,
          loader: false
        })
            
      }            
    }.bind(this));
  }
  
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {[name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  
  alteraSelect(e, {value,name}) {
    this.setState({
      [name]: value.toUpperCase()
    })
  }
  
  salvar(){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    if(this.state.codigo_projeto.length < 2 ||
       this.state.descricao_projeto.length < 2 ||
       this.state.objetivo.length < 2 ||
       this.state.descricao_geral.length < 2 ||
       this.state.escopo.length < 2){
      this.setState({display:display})
    }else{
      if (this.state.data === "--" || this.state.data.length === 8 && this.state.data.match(expre) !== null) {
        this.setState({loading:'true', disabled:'true', displayData: displayNone, display:displayNone});
        var options = {
          method: 'POST',
          url: 'https://inteligenciaindustrial.com/engind/projeto',
          headers: {
            'content-type': 'application/json',
            'x-access-token':getToken()
          },
          body: this.state,
          json: true
        };
        request(options, function (error, response, body) {
          if (error){
            console.log(error)
          }else{
            this.setState({
              codigo_projeto:'',
              descricao_projeto:'',
              objetivo:'',
              descricao_geral:'',
              codigo_status_projeto:'',
              descricao_status_projeto:'',
              escopo:'',
              loading:'',
              disabled:'',
              data: ''
            })
          }            
        }.bind(this));
      } else {
        this.setState({displayData: display});
      }
    }
  }
  
  render(){
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='clipboard' content='Projeto' />
        { this.state.loader && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando status projeto' />
          </Dimmer>
        }
        <Modal.Content>
           <Form>
            <Form.Group>
              <Form.Field width={4} control={Input} label='Código do Projeto' placeholder='Código do Projeto' name='codigo_projeto' onChange={this.altera.bind(this)} value={this.state.codigo_projeto}/>
              <Form.Field width={6} control={Input} label='Nome do Projeto' placeholder='Nome do Projeto' name='descricao_projeto' onChange={this.altera.bind(this)} value={this.state.descricao_projeto}/>          
            </Form.Group>
            <Form.Group>
              <Form.Field width={4} control={Select} label='Status' placeholder='Status' name='codigo_status_projeto' onChange={this.alteraSelect.bind(this)} value={this.state.descricao_status_projeto} search options={this.state.status}/> 
              <Form.Field width={4} control={Input} label='Data' placeholder='00/00/00' name='data' onChange={this.altera.bind(this)} value={this.state.data} search options={this.state.data}/>
              <Message negative style={this.state.displayData}>
                <Icon name='info circle' />
                Preencha o campo data corretamente.
              </Message>
            </Form.Group>
            <Form.Field width={16} control={TextArea} label='Objetivo' placeholder='Objetivo' name='objetivo' onChange={this.altera.bind(this)} value={this.state.objetivo}/>          
            <Form.Field width={16} control={TextArea} label='Descrição Geral' placeholder='Descrição Geral' name='descricao_geral' onChange={this.altera.bind(this)} value={this.state.descricao_geral}/>              
            <Form.Field width={16} control={TextArea} label='Escopo' placeholder='Escopo' name='escopo' onChange={this.altera.bind(this)} value={this.state.escopo}/>          
          </Form>
          <Message negative style={this.state.display}>
            <Icon name='info circle' />
            Não foi possível salvar os dados. Verifique o preenchimento dos campos.
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelar.bind(this)} color='grey'>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
            <Icon name='save outline' /> Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Projeto);