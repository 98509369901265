import React, { Component } from 'react';
import { Grid, Header, Icon, Button  } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alteraUnidadeFabril } from '../../_actions';

class MenuFabricas extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  
  render(){
    const { alteraUnidadeFabril, data_unidadeFabril } = this.props;
    //validaToken(JSON.stringify(true));
    return(
      <div>
        <Grid style={{ height: '100%', width: '380px', margin: 'auto' }}>
          <Grid.Row textAlign='center' >
            <Grid.Column width = {16} style = {{paddingTop: '0px'}}>
              <Header as = 'h5'>SELECIONE UMA FÁBRICA</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign='center' >
            <Grid.Column width = {16} style = {{paddingTop: '0px'}}>
              {
                this.props.UnidadesFabris.map(elemento => {
                  return(
                    <Button key = {elemento.id} style = {{ width: '150px', height: "80px", marginBottom: '8px' }}
                            onClick = {() => alteraUnidadeFabril(elemento)}>
                      {elemento.descricao_unidade_fabril}
                    </Button>
                  );
                })
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

//export default MenuFabricas
const mapStateToProps = store => ({
  data_unidadeFabril: store.alteraUnidadeFabril.data_unidadeFabril
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ alteraUnidadeFabril }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuFabricas);
