import { UPDATE_EXIBE_MODAL } from '../_actions/actionTypes';

const initialState = {
  modal: ''
};
export const exibeModal = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_EXIBE_MODAL':
      return {
        ...state,
        data_exibeModal: action.data_exibeModal
      };
    default:
      return state;
  }
};