import { UPDATE_UNIDADE_FABRIL } from '../_actions/actionTypes';

const initialState = {
  unidadeFabril: ''
};
export const alteraUnidadeFabril = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_UNIDADE_FABRIL':
      return {
        ...state,
        data_unidadeFabril: action.data_unidadeFabril
      };
    default:
      return state;
  }
};