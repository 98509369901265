import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Dimmer, Loader  } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const estados = [
  { key: 'AC', text: 'AC', value: 'AC' },{ key: 'AL', text: 'AL', value: 'AL' },
  { key: 'AP', text: 'AP', value: 'AP' },{ key: 'AM', text: 'AM', value: 'AM' }, 
  { key: 'BA', text: 'BA', value: 'BA' },{ key: 'CE', text: 'CE', value: 'CE' },
  { key: 'DF', text: 'DF', value: 'DF' },{ key: 'ES', text: 'ES', value: 'ES' },
  { key: 'GO', text: 'GO', value: 'GO' },{ key: 'MA', text: 'MA', value: 'MA' },
  { key: 'MT', text: 'MT', value: 'MT' },{ key: 'MS', text: 'MS', value: 'MS' },
  { key: 'MG', text: 'MG', value: 'MG' },{ key: 'PA', text: 'PA', value: 'PA' },
  { key: 'PB', text: 'PB', value: 'PB' },{ key: 'PR', text: 'PR', value: 'PR' },
  { key: 'PE', text: 'PE', value: 'PE' },{ key: 'PI', text: 'PI', value: 'PI' },
  { key: 'RN', text: 'RN', value: 'RN' },{ key: 'RS', text: 'RS', value: 'RS' },
  { key: 'RJ', text: 'RJ', value: 'RJ' },{ key: 'RO', text: 'RO', value: 'RO' },
  { key: 'RR', text: 'RR', value: 'RR' },{ key: 'SC', text: 'SC', value: 'SC' },
  { key: 'SP', text: 'SP', value: 'SP' },{ key: 'SE', text: 'SE', value: 'SE' },
  { key: 'TO', text: 'TO', value: 'TO' }
];

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");

class UnidadeFabril extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo_unidade_fabril:'',
      descricao_unidade_fabril:'',
      abreviatura_unidade_fabril:'',
      cidade:'',
      estado:'',
      endereco:'',
      listaCodigos:[{key:'',text:'',value:''}],
      unidades:[],
      loading:'',
      disabled:'',
      display:displayNone,
      loader: false
    };
    this.atualizaDados = this.atualizaDados.bind(this);
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true, loader: true
    });
    this.atualizaDados();
  }
  
  atualizaDados(){
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/unidadefabril',
      headers: {        
        'x-access-token': getToken()
      }
    };
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        let data = JSON.parse(body);
        let listaCodigos = data.map(function(item){return {key:item.codigo_unidade_fabril,text:item.codigo_unidade_fabril,value:item.codigo_unidade_fabril}});
        this.setState({
          listaCodigos:listaCodigos,
          unidades:data, loader: false
        })
      }            
    }.bind(this));
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {[name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    ); 
  }
  
  alteraSelect(e, {value,name}) {
    this.setState({
      [name]: value.toUpperCase()
    })
  }
  
  filtraLista(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    if (input.getElementsByTagName('span').length === 0) {
      this.setState(
        {[name]: value.toUpperCase()},
        () => input.setSelectionRange(start, end)
      );
    } else {
      this.setState(
        {[name]: value.toUpperCase()}
      );
    }     
    let unidades_filtrado = this.state.unidades.filter(function(data){ return data.codigo_unidade_fabril === value.toUpperCase()  })      
    this.setState({ 
      key:unidades_filtrado[0].key,
      descricao_unidade_fabril:unidades_filtrado[0].descricao_unidade_fabril,
      abreviatura_unidade_fabril:unidades_filtrado[0].abreviatura_unidade_fabril,
      cidade:unidades_filtrado[0].cidade,
      estado:unidades_filtrado[0].estado,
      endereco:unidades_filtrado[0].endereco
    })
  }
  
  salvar(){   
    if(this.state.codigo_unidade_fabril.length < 2 ||
       this.state.descricao_unidade_fabril.length < 2 ||
       this.state.abreviatura_unidade_fabril.length < 2 ||
       this.state.cidade.length < 2 ||
       this.state.estado.length < 2 ||
       this.state.endereco.length < 2){
      this.setState({display:display})
    }else{
      this.setState({loading:'true',disabled:'true'});
      this.setState({display:displayNone})
      var options = {
        method: 'PUT',
        url: 'https://inteligenciaindustrial.com/engind/unidadefabril',
        headers: {
          'content-type': 'application/json',
          'x-access-token': getToken()
        },
        body: this.state,
        json: true
      };
      request(options, function (error, response, body) {
        if (error){
          console.log(error)
        }else{
          this.atualizaDados();
          this.setState({
            codigo_unidade_fabril:'',
            descricao_unidade_fabril:'',
            abreviatura_unidade_fabril:'',
            cidade:'',
            estado:'',
            endereco:'',          
            loading:'',
            disabled:''
          })
        }            
      }.bind(this));  
    }
  }
  
  render(){
    const { exibeModal } = this.state;
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='industry' content='Editar Fábrica' />
        { this.state.loader && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando fábricas' />
          </Dimmer>
        }
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={4} control={Select} label='Código' placeholder='Código' name='codigo_unidade_fabril' onChange={this.filtraLista.bind(this)} value={this.state.codigo_unidade_fabril} options={this.state.listaCodigos} search/>
              <Form.Field width={8} control={Input} label='Descrição' placeholder='Descrição' name='descricao_unidade_fabril' onChange={this.altera.bind(this)} value={this.state.descricao_unidade_fabril}/>
              <Form.Field width={4} control={Input} label='Abreviatura'  placeholder='Abreviatura'  name='abreviatura_unidade_fabril' onChange={this.altera.bind(this)} value={this.state.abreviatura_unidade_fabril}/>
            </Form.Group>                       
            <Form.Group >
              <Form.Field width={12} control={Input} label='Cidade' placeholder='Cidade'  name='cidade' onChange={this.altera.bind(this)} value={this.state.cidade}/>
              <Form.Field width={4} control={Select} label='Estado' placeholder='Estado'  name='estado' onChange={this.alteraSelect.bind(this)} value={this.state.estado} options={estados} search/>          
            </Form.Group>                       
            <Form.Group >
              <Form.Field width={16} control={Input} label='Endereço' placeholder='Endereço'  name='endereco' onChange={this.altera.bind(this)} value={this.state.endereco}/>        
            </Form.Group>
          </Form>
          <Loader active inline='centered' />
          <Message negative style={this.state.display}>
            <Icon name='info circle' />
            Não foi possível salvar os dados. Verifique o preenchimento dos campos.
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelar.bind(this)}color='grey'>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
            <Icon name='save outline' /> Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnidadeFabril);