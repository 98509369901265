import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Grid, Dimmer, Loader, Label, Divider } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");
const axios = require("axios");
const sortBy = require('sort-array');

class Etapas extends Component{
  constructor(props) {
    super(props);
    this.state = {      
      projetos:[],
      listaStatus:[
        {key:'PLANEJADO',text:'PLANEJADO',value:'PLANEJADO'},
        {key:'EM EXECUÇÃO',text:'EM EXECUÇÃO',value:'EM EXECUÇÃO'},
        {key:'PAUSADO',text:'PAUSADO',value:'PAUSADO'},
        {key:'CANCELADO',text:'CANCELADO',value:'CANCELADO'},
        {key:'FINALIZADO',text:'FINALIZADO',value:'FINALIZADO'}
      ],
      key:'',
      codigo_etapa:'',
      descricao_etapa:'',
      inicio_real:'',
      fim_real:'',              
      status:'',        
      codigo_projeto:'',      
      data:[],
      dataCongelado:[],
      loading:'',
      disabled:'',
      display:displayNone,
      displayData: displayNone,
      displayDataImagem: displayNone,
      activeIndex: 0,
      loader: false,
      mensagenLoader: '',
      data_imagem: '',
      sucessoUpload: displayNone,
      erroUpload: displayNone
    };
    this.salvarArquivos = this.salvarArquivos.bind(this);
    this.selecionaArquivos = this.selecionaArquivos.bind(this);
  }
  
  componentDidMount(){
    this.setState({
      loader: true,
      mensagenLoader: 'Carregando projetos'
    });
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/projeto',
      headers: {'x-access-token':localStorage.getItem("token")}
    };
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let projetosFiltrados = data.filter(projeto => {
          return projeto.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        var projetos = projetosFiltrados.map(function(data){
         return { key: data.codigo_projeto, text: data.descricao_projeto , value: data.codigo_projeto }
        })
        this.setState({   
          projetos : projetos,
          loader: false
        })
      }            
    }.bind(this)); 
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
    exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {[name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  
  alteraSelect(e, {value,name}) {
    this.setState({
      [name]: value.toUpperCase()
    })
  }

  salvar(){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    if(this.state.codigo_etapa.length < 2 ||
       this.state.descricao_etapa.length < 2 ||       
       this.state.status.length < 2 ||        
       this.state.codigo_projeto.length < 2){
      this.setState({display:display})
    }else{
      let previsaoInicioIsValid = this.validaCampoData(this.state.inicio_real);
      let previsaoFimIsValid = this.validaCampoData(this.state.fim_real);
      
      if (this.validaCampoData(this.state.inicio_real) && this.validaCampoData(this.state.fim_real)) {
        this.setState({loading:'true', disabled:'true', display:displayNone, displayData: displayNone});
        var options = {
          method: 'PUT',
          url: 'https://inteligenciaindustrial.com/engind/etapa',
          headers: {
            'content-type': 'application/json',
            'x-access-token':localStorage.getItem("token")
          },
          body: { 'key': this.state.key, 'codigo_etapa': this.state.codigo_etapa, 'codigo_projeto': this.state.codigo_projeto, 'descricao_etapa': this.state.descricao_etapa,
                  'descricao_projeto': this.state.descricao_projeto, 'fim_real': this.state.fim_real, 'inicio_real': this.state.inicio_real, 
                  'status': this.state.status },
          json: true
        };
        
        request(options, function (error, response, body) {
          if (error){
            console.log(error)
          }else{
            this.consultaEtapas()
            this.setState({  
              codigo_etapa:'',
              descricao_etapa:'',
              inicio_real:'',
              fim_real:'',
              status:'',
              loading:'',
              disabled:''
            })
          }            
        }.bind(this));
        this.setState({loading:'true', disabled:'true', display:displayNone, displayData:displayNone});
      } else {
        this.setState({displayData: display});
      }
    }
  }

  validaCampoData(data){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    if (data === "--" || data.length === 8 && data.match(expre) !== null) {
      return true
    } else {
      return false
    }
  }

  filtraCodigoProjeto(e,{name, value}){
    this.setState({
      [name]: value.toUpperCase()
    })
    let codigo_projeto = this.state.projetos.filter(function(item){ return item.key === value})
    this.setState({codigo_projeto:codigo_projeto[0].key})
    this.consultaEtapas();
  }

  consultaEtapas(){
    this.setState({
      loader: true,
      mensagenLoader: 'Carregando etapas'
    });
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/etapa',
      headers: {'x-access-token':localStorage.getItem("token")}
    };
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let dataCongelado = data;
        this.setState({dataCongelado:dataCongelado})
        data = data.filter(item=>  item.codigo_projeto === this.state.codigo_projeto);
        data = data.map(function(data){
          return { key: data.descricao_etapa, text: data.codigo_etapa , value: data.codigo_etapa }
        })
        sortBy(data, 'sequencia')
        this.setState({   
          data : data,
          loader: false
        })            
      }            
    }.bind(this));     
  }

  selecionaArquivos(e) {
    let arrayArquivos = [];
    for (let index = 0; index < e.target.files.length; index++) {
      arrayArquivos.push(e.target.files[index]);
    }
    this.setState({ file:e.target.files[0], ArrayArquivos: arrayArquivos });
  }

  salvarArquivos(e){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    this.setState({loading:'true',disabled:'true'});
    let { data_imagem } = this.state;
    if (this.validaCampoData(data_imagem)) {
      data_imagem = data_imagem.split('/');
      data_imagem = data_imagem[0] +'-'+ data_imagem[1] +'-'+ data_imagem[2]
      e.preventDefault();
      const formData = new FormData();
      formData.append('myImage',this.state.file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios.post("https://inteligenciaindustrial.com/engind/arquivos/upload/engind&engind@123"+"&"+this.state.codigo_projeto+"&"+this.state.path+"&"+this.state.codigo_area+"&"+this.state.detalhes_arquivo+"&"+""+"&"+data_imagem+"&"+"",formData,config)
        .then((response) => {   
        let etapaimagem = {codigo_projeto:this.state.codigo_projeto,
                           codigo_etapa:this.state.codigo_etapa,
                           codigo_imagem:response.data.key};

        var options = {
          method: 'POST',
          url: 'https://inteligenciaindustrial.com/engind/etapaimagem',
          headers: {
            'content-type': 'application/json',
            'x-access-token':localStorage.getItem("token")
          },
          body: etapaimagem,
          json: true
        }; 
        request(options, function (error, response, body) {
          if (error){
            console.log(error)
          }else{
            this.setState({                  
              loading:'',
              disabled:''
            })
          }            
        }.bind(this));  
        this.setState({loading:'',disabled:'',sucessoUpload:display,detalhes_arquivo:'',displayDataImagem: displayNone});
      }).catch((error) => {      
        this.setState({loading:'',disabled:'', erroUpload:display,detalhes_arquivo:'',displayDataImagem: displayNone});
      });
    } else {
      this.setState({displayDataImagem: display, loading:'',disabled:''});
    }   
  }

  filtraCodigoEtapa(e,{name,value,key}){    
    let codigo_projeto = this.state.codigo_projeto;
    let itemFiltrado = this.state.dataCongelado.filter(function(e){ return e.codigo_etapa === value && e.codigo_projeto === codigo_projeto });
    this.setState({
      key:itemFiltrado[0].key, 
      descricao_etapa: itemFiltrado[0].descricao_etapa, 
      codigo_etapa:itemFiltrado[0].codigo_etapa,
      inicio_real:itemFiltrado[0].inicio_real,
      fim_real:itemFiltrado[0].fim_real,
      status:itemFiltrado[0].status
    })
  }

  render(){
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='tasks' content='Atualizar Etapas' />
        { this.state.loader && 
          <Dimmer active inverted>
            <Loader inverted content= {this.state.mensagenLoader} />
          </Dimmer>
        }
        <Modal.Content>
           <Form>
            <Form.Group >   
              <Form.Field width={7} control={Select} label='Projeto' placeholder='Projeto'  name='descricao_projeto' onChange={this.filtraCodigoProjeto.bind(this)} value={this.state.descricao_projeto} options={this.state.projetos} search/>          
              <Form.Field width={9} control={Input} label='Código Projeto' placeholder='Código Projeto' readOnly  name='codigo_projeto' value={this.state.codigo_projeto}/>
            </Form.Group>
            <Form.Group>
              <Form.Field width={4} control={Select} label='Código' placeholder='Código'  name='codigo_etapa' onChange={this.filtraCodigoEtapa.bind(this)} value={this.state.codigo_etapa} options={this.state.data} search/>
              <Form.Field width={12} control={Input}  label='Etapa'  placeholder='Etapa' readOnly name='descricao_etapa' value={this.state.descricao_etapa}/>
            </Form.Group>
            <Form.Group>
              <Form.Field width={3} control={Input} label='Início'  placeholder='Início'  name='inicio_real' onChange={this.altera.bind(this)} value={this.state.inicio_real}/>
              <Form.Field width={3} control={Input} label='Fim'  placeholder='Fim'  name='fim_real' onChange={this.altera.bind(this)} value={this.state.fim_real}/>
              <Form.Field width={2} control={Select} label='Status'  placeholder='Status'  name='status' onChange={this.alteraSelect.bind(this)} value={this.state.status} options={this.state.listaStatus}/>
            </Form.Group>
            <Form.Group>
              <Message negative style={this.state.displayData}>
                <Icon name='info circle' />
                Preencha os campo data corretamente.
              </Message>
            </Form.Group>
            <form onSubmit = { this.salvarArquivos } >
              <Divider />
              <Form.Field style = {{width: "130px"}} control={Input} label='Data Imagem' placeholder='00/00/00' name='data_imagem' onChange={this.altera.bind(this)} value={this.state.data_imagem}/>
              <input type = "file" name = "file" onChange = { this.selecionaArquivos } />
              <Message negative style={this.state.displayDataImagem}>
                <Icon name='info circle' />
                  Preencha o campo Data Imagem corretamente
              </Message>
              <Button  loading={this.state.loading} disabled={this.state.disabled} style={{backgroundColor:'#eb5c0a',color:'white', marginTop:'10px'}} type = "submit"> <Icon name='upload' />UPLOAD</Button>
            </form>
            <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={4}>            
              </Grid.Column>
              <Grid.Column width={12}>
                <Button floated='right' style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
                  <Icon name='save outline' /> Salvar
                </Button>
                <Button floated='right' style = {{marginRight: '20px'}} onClick={this.cancelar.bind(this)} color='grey'>
                  <Icon name='remove' /> Cancelar
                </Button>
              </Grid.Column>                      
             </Grid.Row>
            </Grid>
          </Form>
          <Message positive style = {this.state.sucessoUpload}>
            <Icon name='info circle' />
            Upload realizado com sucesso.
          </Message>
          <Message warning style = {this.state.display}>
            <Icon name='info circle' />
            Preencha todos os campos.
          </Message>
          <Message negative style = {this.state.erroUpload}>
            <Icon name='info circle' />
            Erro ao realizar o upload.
          </Message>
        </Modal.Content>   
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Etapas);

