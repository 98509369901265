import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Table, Dimmer, Loader } from 'semantic-ui-react';

import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { getToken } from '../../servicos/controletoken.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const request = require("request");

class Projeto extends Component{
  constructor(props) {
    super(props);
    this.state = {        
      data:[],
      colunas:[
        {headerName: "CÓDIGO", field: "codigo_projeto", width:200},
        {headerName: "PROJETO", field: "descricao_projeto",width:630}
        
      ],
      loader: false,
      modalExclusao: false,
      keyProjetoDeletar: ''
    };
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true,
      loader: true
    });
    this.consultaProjetos();     
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  fechaModalExclusao = () => this.setState({ modalExclusao: false, keyProjetoDeletar: '' })
  
  
  exibeModalExclusao(e){
    this.setState({ modalExclusao: true, keyProjetoDeletar: e.target.getAttribute('data-key') })
  }
  
  excluiEtapa(){
    this.setState({
      loader: true
    });
    let keyProjeto = this.state.keyProjetoDeletar;
    var options = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/engind/projeto',
      headers: {
        'content-type': 'application/json',
        'x-access-token':getToken()
      },
      body: {'key':keyProjeto,'D_E_L_E_T_':'*'},
      json: true
    };
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        this.setState({modalExclusao: false});
        this.consultaProjetos(); 
      }            
    }.bind(this));
  }
  
  consultaProjetos(){
    this.setState({
      loader: true
    });
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/projeto',
      headers: {'x-access-token':getToken()}
    };
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let projetosFiltrados = data.filter(projeto => {
          return projeto.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        this.setState({   
          data : projetosFiltrados,
          loader: false
        })            
      }            
    }.bind(this));
  }
  
  render(){
    return(
      <div>
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='clipboard' content='Lista de Projetos' />
        { this.state.loader && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando projetos' />
          </Dimmer>
        }
        <Modal.Content style={{height:'350px', overflowY:'auto'}} >
         <Table celled fixed compact>                  
                      <Table.Body>
                        {this.state.data.map(({ codigo_projeto, descricao_projeto, key }) => (
                          <Table.Row key={codigo_projeto}>
                            <Table.Cell style={{width:'20%'}}>{codigo_projeto}</Table.Cell>                                                                                               
                            <Table.Cell style={{width:'75%'}}>{descricao_projeto}</Table.Cell>                                                  
                            <Table.Cell style={{width:'5%', textAlign:'center'}}><Icon style={{ cursor:'pointer'}} name='close' data-key={key} onClick={this.exibeModalExclusao.bind(this)}/></Table.Cell>                                                  
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>

        </Modal.Content>    
      </Modal>
      
        <Modal open={this.state.modalExclusao} closeOnEscape={true} closeOnDimmerClick={false} onClose={this.fechaModalExclusao}>
          <Modal.Header>Deletar Projeto</Modal.Header>
          { this.state.loader && 
            <Dimmer active inverted>
              <Loader inverted content='Excluindo o projeto' />
            </Dimmer>
          }
          <Modal.Content>
            <p>VOCÊ TEM CERTEZA QUE DESEJA DELETAR O PROJETO?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.fechaModalExclusao} color = 'grey'>
              NÃO
            </Button>
            <Button
              onClick={this.excluiEtapa.bind(this)}
              color = 'orange'
              labelPosition='right'
              icon='checkmark'
              content='SIM'
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Projeto);