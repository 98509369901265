import React, { Component } from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator';
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css";

import { Modal, Header, Dimmer, Loader, Form, Input, TextArea, Grid, Button, Icon } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const request = require("request");


class Etapas extends Component{
  constructor(props) {
    super(props);
    this.state = {
      //
      Data:[],
      //
      loader: false,
      mensagenLoader: '',
      exibeModalTarefa: false,
      codigo_etapa: '',
      codigo_projeto: '',
      descricao_etapa: '',
      data_tarefa: '',
      atividade_tarefa: ''
    }
    //
    this.salvarEdicao = this.salvarEdicao.bind(this);
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
    exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  componentDidMount(){
    
    this.setState({
      loader: true,
      mensagenLoader: "Carregando etapas cadastradas"
    });
    this.listaEtapa();    
  }
  
  listaEtapa() {
    const abreviaturaFilial = this.props.UnidadeFabril[0].abreviatura_unidade_fabril;
    let getEtapas = {
      method: 'GET',
      url: `https://inteligenciaindustrial.com/engind/etapa/unidadefabril/${abreviaturaFilial}`,
      headers: {        
        'x-access-token':getToken()
      }
    };
    
    request(getEtapas, function (error, response, body) {
      this.setState({
        Data: body,
        loader: false,
        mensagenLoader: ""
      });
    }.bind(this))
  }
  
  salvarEdicao(e, row){
    this.setState({
      loader: true,
      mensagenLoader: "Salvando alteração da etapa"
    });
    let Data = row._cell.row.data;
    var putEtapa = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/engind/etapa',
      headers: {
        'x-access-token':getToken()        
      },
      body: Data,
      json: true
    };
    
    request(putEtapa, function (error, response, body) {
      this.listaEtapa();
    }.bind(this));
  }
  
  remover(e, row){
    this.setState({
      loader: true,
      mensagenLoader: "Removendo etapa"
    });
    let Data = row._cell.row.data;
    var putEtapa = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/engind/etapa',
      headers: {
        'x-access-token':getToken()        
      },
      body: {key: Data.key, D_E_L_E_T_: "*"},
      json: true
    };
    
    request(putEtapa, function (error, response, body) {
      this.listaEtapa();
    }.bind(this));
  }
  
  exibeModalTarefa(e, row){
    let Data = row._cell.row.data;
    this.setState({
      exibeModalTarefa: true,
      codigo_etapa: Data.codigo_etapa,
      codigo_projeto: Data.codigo_projeto,
      descricao_etapa: Data.descricao_etapa
    });
  }
  
  cancelarTarefa(){
    this.setState({
      exibeModalTarefa: false,
      codigo_etapa: '',
      codigo_projeto: '',
      descricao_etapa: '',
      data_tarefa: '',
      atividade_tarefa: ''
      
    });
  }
  
  render(){
    const { Data } = this.state;
    const salvarIcone = function(cell, formatterParams, onRendered){
      return "<i aria-hidden='true' class='save outline large icon'></i>"
    };
    const deleteIcone = function(cell, formatterParams, onRendered){
      return "<i aria-hidden='true' class='close link icon large'></i>"
    };
    const adicionarTarefa = function(cell, formatterParams, onRendered){
      return "<i aria-hidden='true' class='tasks link icon large'></i>"
    };
    
    const Colunas = [
      {
        title: "CÓDIGO ETAPA", 
        field: "codigo_etapa",
        align: "center",
        width: 102, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado
      },
      {
        title: "DESCRIÇÃO ETAPA", 
        field: "descricao_etapa",
        align: "center",
        width: 300, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"input"
      },
      {
        title: "PREVISAO INÍCIO", 
        field: "previsao_inicio",
        align: "center",
        width: 112, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"input"
      },
      {
        title: "PREVISÃO FIM", 
        field: "previsao_fim",
        align: "center",
        width: 112, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"input"
      },
      {
        title: "INÍCIO REAL", 
        field: "inicio_real",
        align: "center",
        width: 90, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"input"
      },
      {
        title: "FIM REAL", 
        field: "fim_real",
        align: "center",
        width: 80, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"input"
      },
      {
        title: "SEQUÊNCIA", 
        field: "sequencia",
        align: "center",
        width: 90, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"input"
      },
      {
        title: "STATUS", 
        field: "status",
        align: "center",
        width: 90, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado,
        editor:"select",
        editorParams:{values:{"PLANEJADO":"PLANEJADO", "EM EXECUÇÃO":"EM EXECUÇÃO", "PAUSADO":"PAUSADO", "CANCELADO": "CANCELADO",
                              "FINALIZADO":"FINALIZADO"}}
      },
      {
        title: "CÓDIGO PROJETO", 
        field: "codigo_projeto",
        align: "center",
        width: 150, 
        headerFilter:"input", 
        headerFilterPlaceholder:" ",
        responsive:0,
        headerFilterFunc:filtroCustomizado
      },
      {formatter:salvarIcone, width:25, align:"center", cellClick: this.salvarEdicao.bind(this)},
      {formatter:deleteIcone, width:25, align:"center", cellClick: this.remover.bind(this)},
      {formatter:adicionarTarefa, width:25, align:"center", cellClick: this.exibeModalTarefa.bind(this)}
    ]

    return(
      <div>
        <Modal open={exibeModal} onClose={this.cancelar.bind(this)} closeIcon size={'fullscreen'}
        style = {{marginLeft: "30px"}}>
          <Header icon='tasks' content='Editar Etapas' />
          { this.state.loader && 
            <Dimmer active inverted>
              <Loader inverted content= {this.state.mensagenLoader} />
            </Dimmer>
          }
          <Modal.Content>
            <ReactTabulator
              data={ Data }
              style={{margin:'0px', fontSize:'9px', height: '480px'}}
              className={'very compact celled'}
              //responsiveLayout={true}
              //options= {Options}
              columns={Colunas}
              tooltips={true}
              layout={"fitColumns"}
          />
          </Modal.Content>   
        </Modal>

        <Modal open={this.state.exibeModalTarefa} onClose={this.cancelarTarefa.bind(this)} closeIcon >
          <Header icon='tasks' content='Adicionar Tarefa' />
          { this.state.loader && 
            <Dimmer active inverted>
              <Loader inverted content= {this.state.mensagenLoaderTarefa} />
            </Dimmer>
          }
          <Modal.Content>
            <Form>
              <Form.Group >   
                <Form.Field width={8} control={Input} label='Código Projeto' name='descricao_projeto' value = {this.state.codigo_projeto} readOnly/>          
                <Form.Field width={8} control={Input} label='Código Etapa' name='descricao_etapa' value = {this.state.codigo_etapa} readOnly/>
              </Form.Group>
              
              <Form.Group >   
                <Form.Field width={13} control={Input} label='Etapa' name='descricao_etapa' value = {this.state.descricao_etapa} readOnly/>          
                <Form.Field width={3} control={Input} label='Data' placeholder='00/00/00' name='data_atividade' /> 
              </Form.Group>

              <Form.Group>
                <Form.Field width={16} control={TextArea} label='Atividade Realizada' name='descricao_atividade'/> 
              </Form.Group>
            </Form>

            <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={4}>            
              </Grid.Column>
              <Grid.Column width={12}>
                <Button floated='right' style={{backgroundColor:'#eb5c0a',color:'white'}} loading={this.state.loading} disabled={this.state.disabled}>
                  <Icon name='save outline' /> Salvar
                </Button>
                <Button floated='right' style = {{marginRight: '20px'}} color='grey' onClick={this.cancelarTarefa.bind(this)}>
                  <Icon name='remove' /> Cancelar
                </Button>
              </Grid.Column>                      
             </Grid.Row>
            </Grid>
          </Modal.Content>   
        </Modal>
      </div>
    );
  }
  
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Etapas);

function filtroCustomizado(headerValue, rowValue, rowData, filterParams){
    

    let headerValueArr = headerValue.split(" ");
    
    let p = '';
    
    for(var x = 0; x < headerValueArr.length; x++){
        
        if(x<headerValueArr.length-1){
             p = p + 'rowValue.toUpperCase().match("'+headerValueArr[x]+'".toUpperCase()) && ';    
        }else{
            p = p + 'rowValue.toUpperCase().match("'+headerValueArr[x]+'".toUpperCase()) ';
        }
        
    }

    return eval(p) ; 
    
}