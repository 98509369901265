import React, { Component } from 'react';
import { versaoAplicativo } from '../../servicos/informacoesaplicativo.js';
import { Transition, Grid, Header, Image, Form, Segment, Button, Message, Loader } from 'semantic-ui-react';
import logoEngind from '../../imagens/logoengind.png';
import logoCidadeImperial from '../../imagens/logocidadeimperial.png';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validaToken } from '../../_actions';

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      Transition: false,
      mensagemSuporte: false,
      mensagemErro: '',
      usuario: '',
      senha: '',
      Loader: false
    };
    this.exibeMensagemSuporte = this.exibeMensagemSuporte.bind(this);
    this.entrar = this.entrar.bind(this);
  }
  
  componentDidMount(){
    this.setState({ Transition: true });
  }
  
  //Controle da exibição da mensagem de suporte
  exibeMensagemSuporte() {
    const { mensagemSuporte } = this.state;
    this.setState({
      mensagemSuporte: mensagemSuporte === true ? false : true
    })
  }
    // Registra no banco de dados toda vez que a tela principal é carregada
  registraAcesso() {
    const request = require('request');
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = `${diaF}/${mesF}/${anoF}`;
    const horaFormatada = `${horaF}:${minutosF}`;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versaoAplicativo +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os;
    const opcoesAcesso = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acesso',
      headers: {
        'content-type': 'application/json'
      },
      body: {"data_acesso": dataFormatada, "hora_acesso": horaFormatada, "codigo_controle_versao": codigoVersao, 
             "key_usuario": ""},
      json: true
    };
    request(opcoesAcesso, function (error, response, body) {
      
    }.bind(this));
  }
  //Valida os dados e realiza o acesso ao aplicativo
  entrar() {
    this.setState({ Loader: true });
    const { usuario, senha } = this.state;
    if (!usuario || !senha) {
      this.setState({ mensagemErro: 'Preencha os campos Usuário e Senha!', Loader: false });
    } else {
      const request = require('request'),
            opcoes = {
              method: 'POST',
              url:'https://inteligenciaindustrial.com/engind/login',
              headers: {
                'content-type': 'application/json'
              },
              body: {"usuario": usuario, "senha": senha},
              json: true
            };
      request(opcoes, function (error, response, body) {
        if (body !== undefined) {
          if (body.mensagem === 'autenticacao valida') {
            localStorage.setItem("token", body.token);
            localStorage.setItem("key", body.usuario.key);
            //this.registraAcesso();
            const { validaToken, data_token } = this.props;
            validaToken(JSON.stringify(true));
            //PubSub.publish('autenticacao', { validado:true });
            this.setState({ Loader:false });
          } else {
            this.setState({ mensagemErro: "Usuário ou senha inválidos! Tente novamente", Loader: false });
          }
        } else {
          this.setState({ mensagemErro: "Não foi possivel conectar-se a internet tente novamente", Loader: false });
        }
      }.bind(this));
    }
  }
  
  render() {
    return(
      
        <div className = 'login-form'>
          <style>
            {`body > div, body > div > div, body > div > div > div.login-form { height: 100%; }`}
          </style>
          <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'middle'>
            <Grid.Column style = {{ maxWidth: '350px' }}>
              <Header as = 'h2' textAlign = 'center'>
                <Image src = { logoEngind } style = {{ width: '250px', padding: '0px', margin: 'auto'}} />
              </Header>
              <Form size = 'large'>
                <Segment stacked>
                <Form.Input fluid icon = 'user' iconPosition = 'left' placeholder = 'Usuário' 
                            type = 'text' name = 'usuario' 
                            onChange = { e => this.setState({ usuario: e.target.value, mensagemErro: '' })}/>
                <Form.Input fluid icon = 'lock' iconPosition = 'left' placeholder = 'Senha' 
                            type = 'password' name = 'senha' 
                            onChange = { e => this.setState({ senha: e.target.value,  mensagemErro: '' })}/>
                { this.state.Loader && <Loader active /> }
                <Button fluid content = 'ENTRAR' icon = 'sign-in alternate' labelPosition = 'right' 
                            color = 'orange' size = 'large' onClick = { this.entrar }/>
                </Segment>
              </Form>
              <Message> Esqueceu usuário ou senha? 
                <a onClick = { this.exibeMensagemSuporte } style = {{ cursor: 'pointer' }}> Clique aqui! </a>
              </Message>
              { 
                this.state.mensagemSuporte && 
                <Message>
                  <i onClick = { this.exibeMensagemSuporte } aria-hidden = 'true' class = 'close icon' />
                  <p>Caso tenha esquecido ou não possua usuário ou senha, mande um e-mail para: bruiz@itucentral.com.br</p>
                </Message> 
              }
              {
                this.state.mensagemErro && 
                <Message color = 'red'> {this.state.mensagemErro} </Message>
              }
              <p class = "ui black center aligned header" style = {{ fontSize: '10px'}}> Desenvolvido por
                <Image src = { logoCidadeImperial } style = {{ width: '75px'}} />
              </p>
              <p class = "ui black center aligned header" style = {{ fontSize: '10px' }}>
                {versaoAplicativo}
              </p>
            </Grid.Column>
          </Grid>
        </div>
    );
  }
}

const mapStateToProps = store => ({
  data_token: store.validaToken.data_token
});


const mapDispatchToProps = dispatch =>
  bindActionCreators({ validaToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
