import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Dimmer, Loader  } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");

class Usuario extends Component{
  constructor(props) {
    super(props);
    this.state = {     
      codigo_usuario:'',
      nome_usuario:'',
      sobrenome_usuario:'',
      funcao:'',
      filial:'',
      email:'',
      telefone:'',
      usuario:'',
      perfil:'',
      loading:'',
      disabled:'',
      display:displayNone,
      codigo_unidade_fabril: '',
      unidades_fabris:[],
      codigo_unidade_fabril_usuario: '',
      codigo_perfil: [],
      codigo_perfil_usuario: '',
      loader_filial: false,
      loader_perfilUsuario: false
    };
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true,
      filial: this.props.UnidadeFabril[0].descricao_unidade_fabril,
      loader_filial: true,
      loader_perfilUsuario: true
    });
    
    var optionsFilial = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/unidadefabril',
      headers: {'x-access-token':getToken()}
    };
    var optionsPerfilUsuario = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/perfilusuario',
      headers: {'x-access-token':getToken()}
    };
    
    request(optionsFilial, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        var filial = data.map(function(data){
         return { key: data.key, text: data.descricao_unidade_fabril, value: data.descricao_unidade_fabril }
        })
        this.setState({   
          unidades_fabris : filial,
          loader_filial: false
        })
            
      }            
    }.bind(this));
    
    request(optionsPerfilUsuario, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        var perfilUsuario = data.map(function(data){
         return { key: data.key, text: data.nome_perfil, value: data.nome_perfil }
        })
        this.setState({   
          codigo_perfil : perfilUsuario,
          loader_perfilUsuario: false
        })
      }            
    }.bind(this));
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {[name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  
  alteraSelect(e, {value,name}) {
    this.setState({
      [name]: value.toUpperCase()
    })
  }
  
  salvar(){
    
     if(this.state.codigo_usuario.length < 2 ||
        this.state.nome_usuario.length < 2 ||
        this.state.sobrenome_usuario.length < 2 ||
        this.state.funcao.length < 2 ||
        this.state.filial.length < 2 ||
        this.state.email.length < 2 ||
        this.state.telefone.length < 2 ||
        this.state.usuario.length < 2 ){
       this.setState({display:display})
     }else{
       this.setState({loading:'true',disabled:'true'});
       this.setState({display:displayNone})
       var options = {
         method: 'POST',
         url: 'https://inteligenciaindustrial.com/engind/usuario',
         headers: {
           'content-type': 'application/json',
           'x-access-token':getToken()
         },
         body: this.state,
         json: true
       };
       
       request(options, function (error, response, body) {
         if (error){
           console.log(error)
         }else{
           this.setState({
             codigo_usuario:'',
             nome_usuario:'',
             sobrenome_usuario:'',
             funcao:'',
             filial:'',
             email:'',
             telefone:'',
             usuario:'',
             perfil:'',
             loading:'',
             disabled:''
           })
         }            
       }.bind(this));  
     }
  }
  
  render() {
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='user' content='Usuário' />
        { this.state.loader_filial && this.state.loader_perfilUsuario && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando perfis' />
          </Dimmer>
        }
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={3} control={Input} label='Código' placeholder='Código' name='codigo_usuario' onChange={this.altera.bind(this)} value={this.state.codigo_usuario}/>
              <Form.Field width={5} control={Input} label='Primeiro Nome' placeholder='Primeiro Nome' name='nome_usuario' onChange={this.altera.bind(this)} value={this.state.nome_usuario}/>
              <Form.Field width={8} control={Input} label='Sobrenome'  placeholder='Sobrenome' name='sobrenome_usuario' onChange={this.altera.bind(this)} value={this.state.sobrenome_usuario}/>
            </Form.Group>                       
            <Form.Group >
              <Form.Field width={8} control={Input} label='Função' placeholder='Função' name='funcao' onChange={this.altera.bind(this)} value={this.state.funcao}/>          
            </Form.Group>                       
            <Form.Group >
              <Form.Field width={8} control={Input} label='E-mail' placeholder='E-mail' name='email' onChange={this.altera.bind(this)} value={this.state.email}/>        
              <Form.Field width={6} control={Input} label='Telefone' placeholder='Telefone' name='telefone' onChange={this.altera.bind(this)} value={this.state.telefone}/>        
            </Form.Group>        
            <Form.Group >
              <Form.Field width={4} control={Input} label='Usuário' placeholder='Usuário' name='usuario' onChange={this.altera.bind(this)} value={this.state.usuario}/>        
              <Form.Field width={4} control={Select} label='Perfil' placeholder='Perfil' name='perfil' onChange={this.alteraSelect.bind(this)} value={this.state.perfil} search options={this.state.codigo_perfil}/>        
            </Form.Group>        
          </Form>
          <Message negative style={this.state.display}>
            <Icon name='info circle' />
            Não foi possível salvar os dados. Verifique o preenchimento dos campos.
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelar.bind(this)} color='grey'>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
            <Icon name='save outline' /> Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Usuario);