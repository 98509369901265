import React from 'react';
import ReactDOM from 'react-dom';

import 'semantic-ui-css/semantic.min.css';
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css";


import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { Store } from './_store';
ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>
, document.getElementById('root'));