import React, { Component } from 'react';



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validaToken } from './_actions';
import Corpo from './componentes/principal/corpo.js';
import Login from './componentes/login/login.js';

import { tokenValido } from './servicos/controletoken.js';

class App extends Component {
  componentDidMount(){
    const { validaToken } = this.props;
    if (tokenValido()) {
      validaToken(JSON.stringify(true));
    } else {
      validaToken(JSON.stringify(false));
    }
  }
  
  render() {
    const { data_token } = this.props;
    if (data_token === 'true') {
      return(<Corpo />)
    } else {
      return(<Login />)
    }
  }
}
      
const mapStateToProps = store => ({
  data_token: store.validaToken.data_token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ validaToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);