import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message, Dimmer, Loader  } from 'semantic-ui-react';

import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { getToken } from '../../servicos/controletoken.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const request = require("request");

class Usuario extends Component{
  constructor(props) {
    super(props);
    this.state = {     
      data:[],
      colunas:[
        {headerName: "CÓDIGO", field: "codigo_usuario", width:120},
        {headerName: "NOME", field: "nome_usuario",width:250},
        {headerName: "SOBRENOME", field: "sobrenome_usuario",width:300},
        {headerName: "FILIAL", field: "filial",width:150}
      ],
      loader: false
    };
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true,
      loader: true
    });
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/usuario',
      headers: {'x-access-token':getToken()}
    };
    
   request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let usuariosFiltrados = data.filter(usuario => {
          return usuario.filial === this.props.UnidadeFabril[0].descricao_unidade_fabril;
        });
        this.setState({   
          data : usuariosFiltrados,
          loader: false
        })
            
      }            
    }.bind(this));
    
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  render(){
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='user' content='Lista de Usuário' />
        <Modal.Content>
                 <div className="ag-theme-material" style={{ height: '300px', width: '850px' }}>
                   { this.state.data.length <= 0 &&
                      <Dimmer active inverted>
                        <Loader inverted content='Carregando usuários' />
                      </Dimmer>
                    }
                    { this.state.data.length > 0 &&
                      <AgGridReact style={{fontSize:'10px !important', width:'830px', height:'350px'}}
                                   rowSelection='multiple'                        
                                   enableColResize={true}                  
                                   enableSorting={true}    
                                   enableFilter={true}
                                   columnDefs={this.state.colunas}
                                   rowData={this.state.data}>
                      </AgGridReact>
                    }
                  </div>      
        </Modal.Content>    
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Usuario);