import React, { Component } from 'react';
import { Grid, Header, Icon, Button, Container,Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

import UnidadeFabril from '../unidadefabril/index';
import EditarUnidadeFabril from '../unidadefabril/editar';
import ListaUnidadeFabril from '../unidadefabril/listar';

import Area from '../area/index';
import EditarArea from '../area/editar';
import ListaArea from '../area/listar';

import Usuario from '../usuario/index';
import EditarUsuario from '../usuario/editar';
import ListaUsuario from '../usuario/listar';

import ResponsavelArea from '../responsavelarea/index';
import ListarResponsavelArea from '../responsavelarea/listar';

import Projeto from '../projeto/index';
import EditarProjeto from '../projeto/editar';
import ListaProjeto from '../projeto/listar';
import Upload from '../projeto/upload';
import Arquivos from '../projeto/arquivos';

import Etapa from '../etapas/index';
import EditarEtapa from '../etapas/editar';
import EditarTodasEtapas from '../etapas/editartodas';

class MenuPrincipal extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalComponente: []
    };
  }
  
  exibeModal(componente) {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal(componente);
  }
  
  render(){
    const { modalComponente } = this.state;
    const { data_exibeModal } = this.props;
    return(
      <div>
        <Container style={{'width':'750px'}}>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Card.Group>
                  <Card>
                    <Card.Content>
                      <Card.Header><Icon floated='right' color='orange' name='industry' /> Fábrica</Card.Header>                    
                    </Card.Content>
                    <Card.Content extra>
                      <Container textAlign='center'>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <UnidadeFabril />)}><Icon name='plus' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <EditarUnidadeFabril />)}><Icon name='edit' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <ListaUnidadeFabril />)}><Icon name='list layout' color='orange'/></Button>
                        {data_exibeModal}
                      </Container>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>
              
              <Grid.Column>
                <Card.Group>
                  <Card>
                    <Card.Content>
                      <Card.Header><Icon floated='right' color='orange' name='sitemap' /> Área</Card.Header>                    
                    </Card.Content>
                    <Card.Content extra>
                      <Container textAlign='center'>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <Area       UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='plus' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <EditarArea UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='edit' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <ListaArea  UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='list layout' color='orange'/></Button>
                      </Container>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>

              <Grid.Column>
                <Card.Group>
                  <Card>
                    <Card.Content>
                      <Card.Header><Icon floated='right' color='orange' name='clipboard' /> Projeto</Card.Header>                    
                    </Card.Content>
                    <Card.Content extra>
                      <Container textAlign='center'>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <Projeto UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='plus' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <EditarProjeto UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='edit' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <ListaProjeto UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='list layout' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <Upload UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='upload' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <Arquivos UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='file alternate' color='orange'/></Button>
                      </Container>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>
              
            </Grid.Row>
      
            <Grid.Row>
              
              <Grid.Column>
                <Card.Group>
                  <Card>
                    <Card.Content>
                      <Card.Header><Icon floated='right' color='orange' name='tasks' /> Etapas</Card.Header>                    
                    </Card.Content>
                    <Card.Content extra>
                      <Container textAlign='center'>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <Etapa UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='plus' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <EditarTodasEtapas UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='edit' color='orange'/></Button>
                        <Button basic circular icon onClick={this.exibeModal.bind(this, <EditarEtapa UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='calendar check outline' color='orange'/></Button>
                      </Container>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>

              { this.props.Usuario[0].filial === 'CORP' &&
                <Grid.Column>
                  <Card.Group>
                    <Card>
                      <Card.Content>
                        <Card.Header><Icon floated='right' color='orange' name='sitemap' /> Usuário</Card.Header>                    
                      </Card.Content>
                      <Card.Content extra>
                        <Container textAlign='center'>
                          <Button basic circular icon onClick={this.exibeModal.bind(this, <Usuario UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='plus' color='orange'/></Button>
                          <Button basic circular icon onClick={this.exibeModal.bind(this, <EditarUsuario UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='edit' color='orange'/></Button>
                          <Button basic circular icon onClick={this.exibeModal.bind(this, <ListaUsuario UnidadeFabril = {this.props.UnidadeFabril}/>)}><Icon name='list layout' color='orange'/></Button>
                        </Container>
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </Grid.Column>
              }


            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

//export default MenuPrincipal;
const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuPrincipal);
