import React, { Component } from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet

//import { clickButton, alteraComponente, clickBotaoUnidadeFabril } from '../../actions';
import { Header, Grid, Icon, Image, Divider, Sidebar, Menu, Segment, Loader } from 'semantic-ui-react';
import logoEngind from '../../imagens/logoengind.png';
import { getToken, getKeyUsuario, removerToken } from '../../servicos/controletoken.js';

import MenuFabricas from '../menufabricas/menufabricas.js';
import MenuPrincipal from '../menuprincipal/menuprincipal.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validaToken, alteraUnidadeFabril } from '../../_actions';


class Corpo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      componente: '',
      exibeSidebar: false,
      exibeLoader: false,
      unidadesFabris: [],
      usuario:[],
      unidadeFabrilUsuario:[]
    };
    this.exibeSidebar = this.exibeSidebar.bind(this);
    this.sair = this.sair.bind(this);
  }
  
  componentDidMount(){
    this.setState({ exibeLoader: true });
    const request = require('request');
    const getUnidadesFabris = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/engind/unidadefabril',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    };
    const getUsuario = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/engind/usuario/' + getKeyUsuario(),
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    }
    request(getUsuario, function (error, response, body) {
      let Usuario = [], UnidadeFabrilUsuario = []
      if (body !== undefined) {
        Usuario = body;
        request(getUnidadesFabris, function (error, response, body) {
          if (body !== undefined) {
            if (body.length > 0) {
              if (Usuario.length !== undefined && Usuario.length > 0) {
                UnidadeFabrilUsuario = body.filter(x => {
                  return x.descricao_unidade_fabril === Usuario[0].filial
                });
              }
              this.setState({unidadesFabris: body, usuario: Usuario, unidadeFabrilUsuario: UnidadeFabrilUsuario, 
                             exibeLoader: false});
            }
            if (body.mensagem) {
              //Token invalido, redireciona para a tela de login
              console.log(body.mensagem)
              const { validaToken, data_token, alteraUnidadeFabril } = this.props;
              removerToken();
              alteraUnidadeFabril('');
              validaToken(JSON.stringify(false));
            }
          } else {
            //quando não consegue consultar a API, exibe uma mensagem de problema com a internet.
            console.log("Erro");
          }
        }.bind(this));
        this.setState({usuario: body})
      } else {
        //quando não consegue consultar a API, exibe uma mensagem de problema com a internet.
        console.log("Erro");
      }
    }.bind(this));    
  }
  
  exibeSidebar() {
    const { exibeSidebar } = this.state;
    this.setState({
      exibeSidebar: exibeSidebar === true ? false : true
    });

  }
  
  sair(){
    const { validaToken, data_token, alteraUnidadeFabril } = this.props;
    removerToken();
    alteraUnidadeFabril('');
    validaToken(JSON.stringify(false));
  }

  render() {
    let { exibeSidebar, unidadesFabris, usuario, unidadeFabrilUsuario } = this.state;
    const {
      data_unidadeFabril, validaToken, data_token
    } = this.props;
    if (data_unidadeFabril !== undefined && unidadeFabrilUsuario.length === 0) {
      unidadeFabrilUsuario.push(data_unidadeFabril);
    }
    let ComponenteAtual = '';
    if (unidadesFabris.length >0) {
      if (unidadeFabrilUsuario.length > 0) {
        ComponenteAtual = <MenuPrincipal UnidadeFabril = { unidadeFabrilUsuario } Usuario = { usuario }/>
      } else {
        ComponenteAtual = <MenuFabricas UnidadesFabris = { unidadesFabris }/>
      }
    }
    return (
      <div style = {{ maxWidth: '100%', margin: 'auto'}}>
        <Header>
          <Grid>
            <Grid.Column width={2}>
              <Icon name = 'sidebar' link style={{ marginTop:'20px', marginLeft:'10px' }} disabled = {exibeSidebar} 
                    onClick = { this.exibeSidebar } />
            </Grid.Column >
            <Grid.Column width={12} style={{textAlign: 'center'}}>
              <Header.Content>
                <Image src = { logoEngind } style = {{ width: '130px', paddingTop: '8px', cursor: 'pointer'}} />
              </Header.Content>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid>
        </Header>
        <Divider style = {{paddingTop: '0px', marginBottom: '0px'}}/>
        <Sidebar.Pushable style = {{ padding: '0px'}}>
          <Sidebar as = { Menu } animation = 'overlay' inverted onHide = { this.exibeSidebar } direction = 'top' 
                   visible = { exibeSidebar }width = 'thin'>
            <Menu.Item as = 'a' onClick = { this.sair }>
              <Icon name = 'log out' />
                Sair
            </Menu.Item>
          </Sidebar>
          <Sidebar.Pusher>
            <Segment basic style = {{maxWidth: "100%", margin: 'auto'}}>
              { this.state.exibeLoader && <Loader active inline='centered'></Loader> }
              { ComponenteAtual }
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

    
    
const mapStateToProps = store => ({
  data_unidadeFabril: store.alteraUnidadeFabril.data_unidadeFabril,
  data_token: store.validaToken.data_token
});


const mapDispatchToProps = dispatch =>
  bindActionCreators({ alteraUnidadeFabril, validaToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Corpo);