import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, 
         Message, Segment, Grid, Accordion, Label, Table, Dimmer, Loader } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");
const sortBy = require('sort-array');

class Etapas extends Component{
  constructor(props) {
    super(props);
    this.state = {      
      projetos:[],
      colunas:[
        {headerName: "CÓDIGO ETAPA", field: "codigo_etapa", width:175},
        {headerName: "ETAPA", field: "etapa",width:500},
        {headerName: "PREVISÃO INÍCIO", field: "previsao_inicio",width:200},
        {headerName: "PREVISÃO FIM", field: "previsao_fim",width:200}
      ],      
      codigo_etapa:'',
      descricao_etapa:'',
      previsao_inicio:'',
      previsao_fim:'',
      inicio_real:'',
      fim_real:'',
      sequencia:'',
      status:'',        
      codigo_projeto:'',      
      data:[],
      loading:'',
      disabled:'',
      display:displayNone,
      displayData: displayNone,
      activeIndex: 0,
      loader_projetos: false,
      loader_etapas: false,
      modalExclusao: false,
      keyEtapaDeletar: '',
      listaEtapas: []
    };
  }
  
  componentDidMount(){
    this.setState({
      loader_projetos: true
    });
    
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/projeto',
      headers: {'x-access-token':getToken()}
    };
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let projetosFiltrados = data.filter(projeto => {
          return projeto.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        var projetos = projetosFiltrados.map(function(data){
          return { key: data.codigo_projeto, text: data.descricao_projeto , value: data.codigo_projeto }
        })
        this.setState({   
          projetos : projetos,
          loader_projetos: false
        })
      }            
    }.bind(this)); 
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal([]);
    this.setState({
      exibeModal: false
    });
  }
  
  fechaModalExclusao = () => this.setState({ modalExclusao: false, keyProjetoDeletar: '' })
  
  
  exibeModalExclusao(e){
    this.setState({ modalExclusao: true, keyEtapaDeletar: e.target.getAttribute('data-key') })
  }
  
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  
  altera(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {[name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  
  alteraSelect(e, {value,name}) {
    this.setState({
      [name]: value.toUpperCase()
    })
  }

  alteraDescricaoEtapa(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const etapa = this.state.listaEtapas.filter(x => {
      return x.codigo_etapa === value
    });
    if (etapa.length > 0) {
      this.setState(
        {[name]: value.toUpperCase(),
        descricao_etapa: etapa[0].descricao_etapa},
        () => input.setSelectionRange(start, end)
      );
    } else {
      this.setState(
        {[name]: value.toUpperCase()},
        () => input.setSelectionRange(start, end)
      );
    }
  }
  
  alteraCodigoEtapa(e, {value,name}) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const etapa = this.state.listaEtapas.filter(x => {
      return x.descricao_etapa === value
    });
    if (etapa.length > 0) {
      this.setState(
        {[name]: value.toUpperCase(),
        codigo_etapa: etapa[0].codigo_etapa},
        () => input.setSelectionRange(start, end)
      );
    } else {
      this.setState(
        {[name]: value.toUpperCase()},
        () => input.setSelectionRange(start, end)
      );
    }
  }

  salvar(){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    if(this.state.codigo_etapa.length < 2 ||
       this.state.descricao_etapa.length < 2 ||       
       this.state.sequencia.length < 2 ||        
       this.state.codigo_projeto.length < 2){
      this.setState({display:display})
    }else{
      let previsaoInicioIsValid = this.validaCampoData(this.state.previsao_inicio);
      let previsaoFimIsValid = this.validaCampoData(this.state.previsao_fim);
      
      if (this.validaCampoData(this.state.previsao_inicio) && this.validaCampoData(this.state.previsao_fim)) {
        var options = {
          method: 'POST',
          url: 'https://inteligenciaindustrial.com/engind/etapa',
          headers: {
            'content-type': 'application/json',
            'x-access-token':getToken()
          },
          body: {'codigo_etapa': this.state.codigo_etapa, 'codigo_projeto': this.state.codigo_projeto, 
                 'descricao_etapa': this.state.descricao_etapa, 'descricao_projeto': this.state.descricao_projeto,
                 'fim_real': this.state.fim_real, 'inicio_real': this.state.inicio_real, 'previsao_fim': this.state.previsao_fim,
                 'previsao_inicio': this.state.previsao_inicio, 'sequencia': this.state.sequencia, 'status': this.state.status},
          json: true
        };
        request(options, function (error, response, body) {
          if (error){
            console.log(error)
          }else{
            this.consultaEtapas()
            this.setState({  
              codigo_etapa:'',
              descricao_etapa:'',
              previsao_inicio:'',
              previsao_fim:'',
              sequencia:'',
              loading:'',
              disabled:''
            })
          }            
      }.bind(this));
        this.setState({loading:'true', disabled:'true', display:displayNone, displayData:displayNone});
      } else {
        this.setState({displayData: display});
      }
    }
  }

  validaCampoData(data){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    if (data === "--" || data.length === 8 && data.match(expre) !== null) {
      return true
    } else {
      return false
    }
  }

  filtraCodigoProjeto(e,{name, value}){
    this.setState({display:displayNone})
    this.setState({[name]:value.toUpperCase()}) 
    let codigo_projeto = this.state.projetos.filter(function(item){ return item.key === value})
    this.setState({codigo_projeto:codigo_projeto[0].key})
    this.consultaEtapas();
  }

  consultaEtapas(){
    this.setState({
      loader_etapas: true
    });
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/etapa',
      headers: {'x-access-token':getToken()}
    };
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let listaEtapas = [];
        listaEtapas = data
        .map(e => e['codigo_etapa'])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => data[e]).map(e => data[e]);
        data = data.filter(item=>  item.codigo_projeto === this.state.codigo_projeto);
        sortBy(data, 'sequencia');
        this.setState({   
          data : data,
          loader_etapas: false,
          listaEtapas: listaEtapas,
        })            
      }            
    }.bind(this));     
  }

  excluiEtapa(){
    this.setState({
      loader: true
    });
    let keyEtapa = this.state.keyEtapaDeletar;
    var options = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/engind/etapa',
      headers: {
        'content-type': 'application/json',
        'x-access-token':getToken()
      },
      body: {'key':keyEtapa,'D_E_L_E_T_':'*'},
      json: true
    };
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        this.setState({modalExclusao: false});
        this.consultaEtapas(); 
      }            
    }.bind(this));     
  }

  render(){
    const { activeIndex } = this.state;
    return(
      <div>
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='tasks' content='Etapas' />
        { this.state.loader_projetos && 
          <Dimmer active inverted>
            <Loader inverted content='Carregando projetos' />
          </Dimmer>
        }
        <Modal.Content>
           <Form>
            <Form.Group >   
              <Form.Field width={7} control={Select} label='Projeto' placeholder='Projeto'  name='descricao_projeto' onChange={this.filtraCodigoProjeto.bind(this)} value={this.state.descricao_projeto} options={this.state.projetos} search/>          
              <Form.Field width={9} control={Input} label='Código Projeto' placeholder='Código Projeto' readOnly  name='codigo_projeto' value={this.state.codigo_projeto}/>
            </Form.Group>
            <Form.Group>
              <Form.Field width={4} control={Input} list='codigo_etapa' label='Código' placeholder='Código'  name='codigo_etapa' onChange={this.alteraDescricaoEtapa.bind(this)} value={this.state.codigo_etapa}/>
              <datalist id='codigo_etapa'>
                {
                  this.state.listaEtapas.map(function(x){
                    return <option key = {x.key} value = {x.codigo_etapa} />
                  })

                }
              </datalist> 
              <Form.Field width={12} control={Input} list = 'descricao_etapa' label='Etapa' placeholder='Etapa'  name='descricao_etapa' onChange={this.alteraCodigoEtapa.bind(this)} value={this.state.descricao_etapa}/>
              <datalist id='descricao_etapa'>
                {
                  this.state.listaEtapas.map(function(x){
                    return <option key = {x.key} value = {x.descricao_etapa} />
                  })

                }
              </datalist> 
            </Form.Group>
            <Form.Group>
              <Form.Field width={3} control={Input} label='Previsão Início'  placeholder='Previsão Início'  name='previsao_inicio' onChange={this.altera.bind(this)} value={this.state.previsao_inicio}/>
              <Form.Field width={3} control={Input} label='Previsão Fim'  placeholder='Previsão Fim'  name='previsao_fim' onChange={this.altera.bind(this)} value={this.state.previsao_fim}/>
              <Form.Field width={2} control={Input} label='Sequência'  placeholder='Sequência'  name='sequencia' onChange={this.altera.bind(this)} value={this.state.sequencia}/>
              <Message negative style={this.state.displayData}>
                <Icon name='info circle' />
                Preencha os campo data corretamente.
              </Message>
            </Form.Group>                               
            <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={4}>            
              </Grid.Column>
              <Grid.Column width={12}>
                <Button floated='right' style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
                  <Icon name='save outline' /> Salvar
                </Button>
                <Button floated='right' style = {{marginRight: '20px'}} onClick={this.cancelar.bind(this)} color='grey'>
                  <Icon name='remove' /> Cancelar
                </Button>
              </Grid.Column>                      
             </Grid.Row>
            </Grid>
          </Form>
          <Message warning style={this.state.display}>
            <Icon name='info circle' />
            Preencha todos os campos.
          </Message>
          <Segment clearing style={{width:'100%',height:'250px', overflowY:'auto'}}>
            { this.state.loader_etapas && 
              <Dimmer active inverted>
                <Loader inverted content='Carregando etapas' />
              </Dimmer>
            }
            <Table celled fixed compact>                  
              <Table.Body>
                {this.state.data.map(({ codigo_etapa, descricao_etapa,previsao_inicio,previsao_fim,sequencia, key }) => (
                  <Table.Row key={codigo_etapa}>
                    <Table.Cell style={{width:'5%'}}>{sequencia}</Table.Cell>                        
                    <Table.Cell style={{width:'12%'}}>{codigo_etapa}</Table.Cell>                        
                    <Table.Cell style={{width:'49%'}}>{descricao_etapa}</Table.Cell>                        
                    <Table.Cell style={{width:'10%'}}>{previsao_inicio}</Table.Cell>                        
                    <Table.Cell style={{width:'10%'}}>{previsao_fim}</Table.Cell>                                                  
                    <Table.Cell style={{width:'5%', textAlign:'center'}}><Icon style={{ cursor:'pointer'}} name='close' data-key={key} onClick={this.exibeModalExclusao.bind(this)}/></Table.Cell>                                                  
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>   
      </Modal>
      
      <Modal open={this.state.modalExclusao} closeOnEscape={true} closeOnDimmerClick={false} onClose={this.fechaModalExclusao}>
          <Modal.Header>DELETAR ETAPA</Modal.Header>
          { this.state.loader && 
            <Dimmer active inverted>
              <Loader inverted content='Excluindo etapa' />
            </Dimmer>
          }
          <Modal.Content>
            <p>VOCÊ TEM CERTEZA QUE DESEJA DELETAR A ETAPA?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.fechaModalExclusao} color = "grey">
              NÃO
            </Button>
            <Button
              onClick={this.excluiEtapa.bind(this)}
              color= 'orange'
              labelPosition='right'
              icon='checkmark'
              content='SIM'
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Etapas);


