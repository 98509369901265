import { UPDATE_TOKEN } from '../_actions/actionTypes';

const initialState = {
  token: ''
};
export const validaToken = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TOKEN':
      return {
        ...state,
        data_token: action.data_token
      };
    default:
      return state;
  }
};