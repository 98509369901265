import React, { Component } from 'react';
import { Button, Header, Icon, Modal,  Checkbox, Form, Input, Radio, Select, TextArea, Message  } from 'semantic-ui-react';
import { getToken } from '../../servicos/controletoken.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exibeModal } from '../../_actions';

const displayNone = {display:'none'};
const display = {display:''};
const request = require("request");

class ResponsavelArea extends Component{
  constructor(props) {
    super(props);
    this.state = {     
      codigo_area:'',
      codigo_usuario:'',
      areas:[],
      usuarios:[],
      loading:'',
      disabled:'',
      display:displayNone
    };
  }
  
  componentDidMount(){
    this.setState({
      exibeModal: true
    });
    
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/area',
      headers: {'x-access-token':getToken()}
    };
    
    var options2 = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/engind/usuario',
      headers: {'x-access-token':getToken()}
    };
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        let areasFiltrada = data.filter(area => {
          return area.codigo_unidade_fabril === this.props.UnidadeFabril[0].codigo_unidade_fabril;
        });
        var areas = areasFiltrada.map(function(data){
         return { key: data.codigo_area, text: data.descricao_area, value: data.codigo_area }
        })
        this.setState({   
          areas : areas
        })
            
      }            
    }.bind(this)); 
    
    request(options2, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var data = JSON.parse(body);
        var usuarios = data.map(function(data){
         return { key: data.codigo_usuario, text: data.nome_usuario + ' ' + data.sobrenome_usuario + ' - ' + data.funcao, value: data.codigo_usuario }
        })
        this.setState({   
          usuarios : usuarios
        })
            
      }            
    }.bind(this)); 
    
  }
  
  cancelar() {
    const { exibeModal, data_exibeModal } = this.props;
            exibeModal(JSON.stringify(''));
    this.setState({
      exibeModal: false
    });
  }
  
  altera(e, {value,name}) {
    this.setState({[name]:value.toUpperCase()}) 
  }
  
  salvar(){
   
    if(this.state.codigo_usuario.length < 2 ||
       this.state.codigo_area.length < 2){
      this.setState({display:display})
    }else{
      this.setState({loading:'true',disabled:'true'});
      this.setState({display:displayNone})
      var options = {
        method: 'POST',
        url: 'https://inteligenciaindustrial.com/engind/responsavelarea',
        headers: {
          'content-type': 'application/json',
          'x-access-token':getToken()
        },
        body: this.state,
        json: true
      };
      
      request(options, function (error, response, body) {
        if (error){
          console.log(error)
        }else{
          this.setState({
            codigo_area:'',
            codigo_usuario:'',
            loading:'',
            disabled:''
          })
        }            
      }.bind(this));  
    }
  }
  
  render() {
    return(
      <Modal open={exibeModal} onClose={this.cancelar.bind(this)}dimmer={'blurring'} closeIcon>
        <Header icon='user outline' content='Responsável por Área' />
        <Modal.Content>
           <Form>
            <Form.Group>
              <Form.Field width={6} control={Select} label='Área' placeholder='Área'  name='codigo_area' onChange={this.altera.bind(this)} value={this.state.codigo_area} options={this.state.areas}/>
              <Form.Field width={10} control={Select} label='Responsável' placeholder='Responsável'  name='codigo_usuario' onChange={this.altera.bind(this)} value={this.state.codigo_usuario} options={this.state.usuarios}/>          
            </Form.Group>                               
          </Form>
          <Message negative style={this.state.display}>
            <Icon name='info circle' />
            Não foi possível salvar os dados. Verifique o preenchimento dos campos.
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelar.bind(this)} color='grey'>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button style={{backgroundColor:'#eb5c0a',color:'white'}} onClick={this.salvar.bind(this)} loading={this.state.loading} disabled={this.state.disabled}>
            <Icon name='save outline' /> Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  data_exibeModal: store.exibeModal.data_exibeModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exibeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResponsavelArea);